<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='0' >
    <div class="">
      <!-- banner开始 -->
      <div class="banner w flex justify-center items-center" :style="{backgroundImage: 'url(' + indexBg + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}">
        <img class="banner-logo wow fadeInUp" src="../assets/images/white-logo.png" alt="双美豆沙牛乳">
      </div>
      <!-- banner结束 -->
      <!-- 01开始 -->
      <div class="container-one w bg-white row py-60 justify-content-between">
        <steps class="container-left col-md-1" num='01' title='关于双美' color='#CA4938' ></steps>
        <div class="container-center h-full col-md-10 row p-0 m-0">

          <video
            v-if="mobile === 'iPhone'"
            id="video"
            ref="videoPlay"
            class="h-full w-full col-md-7 p-0 m-0"
            controls
            preload="none"
            webkit-playsinline
            x5-video-player-type="h5"
            :playsinline="true"
            :src="aboutVideo"
          ></video>
          <video
            v-else
            id="video"
            ref="videoPlay"
            class="h-full w-full col-md-7 p-0 m-0"
            :x5-video-player-fullscreen="true"
            x5-playsinline
            playsinline
            webkit-playsinline
            :src="aboutVideo"
            controls
          ></video>
          <div class="h-full col-md-5 m-0">
            <swiperAbout class="swiperAbout"></swiperAbout>
          </div>
        </div>
        <div class="container-right h-full bg-red col-md-1 cursor-pointer flex flex-col items-center an-right " @click="toNext('/about')">
          <div class="icon-more mt-60">
            <iconFont class="icon " icon='thinarrow' color='#fff' :size='18' ></iconFont>
          </div>
          <div class="text-landscape text-white mt-10 ">查看更多</div>
        </div>
        <div class="container-more-btn bg-red cursor-pointer text-center text-14 text-white mt-30" @click="toNext('/about')">
          查看更多
        </div>
      </div>
      <!-- 01结束 -->

      <!-- 02开始 -->
      <div class="container-two w">
        <div class="w-full bg-white p-0 m-0">
          <div class="w-full container-two-top row p-0 m-0">
            <steps class="container-left col-md-1" num='02' title='工艺流程' color='#CA4938' ></steps>
            <div class="container-center h-full col-md-11 row p-0 m-0 wow fadeInLeft">
              <div class="pc-show w-full process-pc"></div>
              <div class="mb-show w-full process-mb" ></div>
              <!-- <img class="pc-show w-full " src="../assets/images/technological-process.png" alt=""> -->
              <!-- <img class="mb-show process-mb w-full " src="../assets/images/technological-process.png" alt=""> -->
            </div>
          </div>
          <div class="container-two-bottom w-full flex p-0 m-0 justify-between">
            <!-- <div class="col-md-1 p-0 m-0"></div> -->
            <div class="container-two-bottom-left col-md-8 text-white p-0 m-0">
              <div class="container-two-bottom-left-tag h-60 font-XQ text-white relative">
                CRAFT
                <iconFont class="icon relative bottom-20" icon='wavyline' color='#fff' :size='60' ></iconFont>
              </div>
              <div class="container-two-bottom-left-title font-XQ text-40 text-white wow fadeInDown">精雕細琢 細烹慢制 八道工藝呈絕味</div>
              <div class="mt-30 leading-8 wow fadeInUp">
                爽利可口的豆沙牛乳问世之后，经阿柱家几代人的改良与传承，秉承着最初对食材的精挑细选的坚持，再加上日趋成熟的一道道匠心手工，最终八道完整的制作工序传承了下来。
              </div>
              <div class="mt-30 leading-8 wow fadeInUp">
                一颗颗精挑细选过的高山毛绿豆，要经过“浸泡”“杀青”“过滤”“蒸制”“熬煮”“蜜制”“破壁”“冷却”后再加入纯粹的牛乳后,最终成为一杯看似平凡，却又在台湾街头广为流传的经典饮品。
              </div>
            </div>
            <div class="container-two-bottom-right p-0 m-0 flex flex-col items-center cursor-pointer an-right" @click="toNext('/craft')">
              <div class="icon-more-big icon-more mt-60">
                <iconFont class="icon" icon='thinarrow' color='#fff' :size='50' ></iconFont>
              </div>
              <div class="text-white mt-10">查看更多</div>
            </div>
          </div>
        </div>
        <div class="w-full text-center">
          <div class="container-more-btn bg-white cursor-pointer text-center text-14 text-red mt-30" @click="toNext('/craft')">
            查看更多
          </div>
        </div>
      </div>
      <!-- 02结束 -->

      <!-- 03开始 -->
      <div class="container-three w">
        <steps class="pc-show container-left col-md-1" num='03' title='产品在线' color='#fff' ></steps>
        <steps class="mb-show container-left col-md-1" num='03' title='产品在线' color='#CA4938' ></steps>
        <div class="container-center h-full w-full p-0 m-0">
          <swiperProduct class="h-full" v-if="productList.length" :list="productList" ></swiperProduct>
        </div>
        <div class="container-right pl-0 m-0 flex flex-col items-center cursor-pointer pt-80 pr-100 an-right" @click="toNext('/product')">
          <div class="icon-more-big icon-more mt-60">
            <iconFont class="icon" icon='thinarrow' color='#fff' :size='50' ></iconFont>
          </div>
          <div class="text-white mt-10">查看更多</div>
        </div>
        <div class="w-full text-center pb-20">
          <div class="container-more-btn bg-red cursor-pointer text-center text-14 text-white mt-30" @click="toNext('/product')">
            查看更多
          </div>
        </div>
      </div>
      <!-- 03结束 -->

      <!-- 04开始 -->
      <div class="container-four w bg-white row justify-content-between">
        <steps class="pc-show col-md-1" num='04' title='严正声明' color='#fff' ></steps>
        <div class="container-center h-full col-md-10 px-0 m-0 text-white text-center box-border wow fadeIn">
          <div class="container-center-box py-50">
            <div class="font-XQ text-40">严正声明</div>
            <div class="font-XQ">STATEMENT</div>
            <div class="container-auto overflow-y-auto text-left">
              <div v-html="solemnStatement" ></div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <!-- 04结束 -->

      <!-- 05开始 -->
      <div class="container-five w">
        <div class="container-five-box w-full p-0 m-0">
          <div class="container-five-bg"></div>
          <div class="w-full container-five-top row p-0 m-0">
            <steps class="col-md-1" num='05' title='门店分布' color='#CA4938' ></steps>
            <div class="container-center h-full col-md-11 row p-0 m-0 wow fadeInDown" ></div>
          </div>
          <div class="pc-show container-five-bottom p-0 m-0 row p-0 m-0">
            <div class="col-md-1 p-0 m-0"></div>
            <swiperShop v-if="shopList.length" :list="shopList" class="col-md-11 p-0 m-0"></swiperShop>
          </div>
          <div class="mb-show mx-10">
            <swiperShopMb v-if="shopList.length" :list="shopList" ></swiperShopMb>
          </div>
        </div>
        <div class="w-full text-center py-20">
          <div class="container-more-btn bg-white cursor-pointer text-center text-14 text-red mt-30" @click="$push('/shop')">
            查看更多
          </div>
        </div>
      </div>
      <!-- 05结束 -->

      <!-- 06开始 -->
      <div class="w">
        <joiningProcess num='06' :isIndex="true"  ></joiningProcess>
      </div>
      <!-- 06结束 -->

      <!-- 07开始 -->
      <toSupport class="w" num='07' ></toSupport>
      <!-- 07结束 -->

      <!-- 背景图开始 -->
      <div class="center-bg"></div>
      <!-- 背景图结束 -->

      <!-- 08开始 -->
      <leaveMessage class="w" num='08' ></leaveMessage>
      <!-- 08结束 -->
      <popUp :Wrapper="Wrapper" :FadeAnimate="FadeAnimate" :html='solemnStatement' @close="close" ></popUp>

    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'
import swiperAbout from '@/components/swiperAbout'
import swiperProduct from '@/components/swiperProduct'
import swiperShop from '@/components/swiperShop'
import swiperShopMb from '@/components/swiperShopMb'
import steps from '@/components/steps'
import popUp from '@/components/popUp'
import leaveMessage from '@/components/leaveMessage'

import toSupport from '@/components/toSupport'

import joiningProcess from '@/components/joiningProcess'

import iconFont from '@/components/iconFont'

export default {
  metaInfo: {
    title: '双美豆沙牛乳加盟_双美加盟台湾古早味_加盟双美豆沙牛乳店-河北纳齐餐饮管理有限公司【官网】',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳_双美豆沙牛乳官网_双美豆沙牛乳加盟'
      },
      {
        name: 'description',
        content: '双美豆沙牛乳官网为您提供加盟等信息，双美豆沙牛乳精选来自台湾的高品质原料，在追求潮流风尚的同时不忘天然与健康。芋圆仙草、绿豆沙和牛乳的完美组合，成就了豆沙牛乳风靡台湾的古早味饮品，始于经典又融入现代流行元素，双美豆沙牛乳加盟值得信赖!'
      }
    ]
  },
  data () {
    return {
      status: 'waiting',
      loadingShow: true,
      msg: 'Welcome to Your Vue.js App',
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      Wrapper: false,
      FadeAnimate: false,
      productList: [], // 产品数据
      shopList: [],
      indexBg: 'https://s3.moke.cloud/shuangmei/5288a499-4bdc-42be-8315-d1c799ba648d.jpg', // 背景图
      aboutVideo: '', // 视频
      solemnStatement: '', // 严正声明
      mobile: '',
      text: '', // 需要实时压缩的文本
    }
  },
  async mounted () {
    // this.tests()
    this.getInit()
    this.Wrapper = true
    this.FadeAnimate = true
    // window.removeEventListener('scroll', this.scrollToTop)
  },
  components: {
    swiperAbout,
    swiperProduct,
    swiperShop,
    swiperShopMb,
    steps,
    iconFont,
    popUp,
    leaveMessage,
    toSupport,
    joiningProcess

  },
  computed: {
  },
  activated: function () {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    this.mobile = isiOS ? 'iPhone' : 'android'
    // window.addEventListener('scroll', this.scrollToTop)
  },
  deactivated: function () {
    // console.log('deactivated')
    // window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // async tests () {
    //   try {
    //     console.log('测试')

    //     // console.log(list)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    scrollToTop () {
      setTimeout(() => {
        let id = document.getElementById(`video`)
        // console.log(id)
        if (!id) {
          // 移除滚动监听
          return
        }
        const offset = id.getBoundingClientRect()
        const offsetTop = offset.top
        const offsetBottom = offset.bottom
        // const offsetHeight = offset.height;
        // 进入可视区域
        // console.log(offsetTop,offsetBottom)
        if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
          // console.log('进入可视区域')
          // 获取视频当前状态，，是否暂停
          // console.log(id)
          // console.log(id.paused)
          if (id.paused) {
            id.play()
          }
        } else {
          // console.log('移出可视区域')
          // 获取视频当前状态，，是否播放
          if (!id.paused) {
          // 暂停视频
            id.pause()
          }
        }
      }, 0)
    },
    funEnd () {
      setTimeout(() => {
        this.status = 'success'
        this.$nextTick(() => {
          // 在dom渲染完后,再执行动画
          const wow = new WOW({
            live: false
          })
          wow.init()
        })
      }, 300)
    },
    async getInit () {
      try {
        // this.status = 'waiting'
        // 获取产品数据
        this.text = ''
        await this.getSetting()
        await this.getProduct()
        await this.getShop()
        await this.getFont(this.text)
        this.requestd = true
        this.status = 'requestEnd'
      } catch (e) {
        this.status = 'error'
      }
    },

    async getProduct () {
      let list = await this.$axios.get('/classes/product', {
        params: {
          order: '-createdAt',
          where: {
            type: 'feature'
          }
        }
      })
      this.productList = list.results
      list.results.forEach(item => {
        this.text += item.name
      })
    },
    async toNext (url) {
      this.$push({
        path: url
      })
    },
    async getShop () {
      let list = await this.$axios.get('/classes/shopList', {
        params: {
          order: '-createdAt',
          limit: 10
        }
      })
      this.shopList = list.results
      list.results.forEach(item => {
        this.text += item.name
      })
    },
    showModal () {
      this.visible = true
    },
    close (e) {
      var That = this
      this.FadeAnimate = false
      setTimeout(function () {
        That.Wrapper = false
      }, 200)
    },
    handleCancel (e) {
      this.visible = false
    },
    async getSetting () {
      let settings = await this.$axios.get('/settings')
      this.indexBg = settings.indexBg
      this.aboutVideo = settings.aboutVideo
      this.solemnStatement = settings.solemnStatement
    },
  }
}
</script>

<style scoped lang="scss">

@keyframes FadeIn{
    0% {transform: scale(0.8)}
    100% {transform:scale(1)}
}

@keyframes FadeOut{
    0% {transform: scale(1)}
    100% {transform:scale(0.8s)}
}
@import '@/assets/scss/mixin1700.scss';

.banner{
  height: 937px;
  @include hamburger {
    height: 550px !important;
  }
  .banner-logo{
    width: 280px;
    height: 322px;
    @include hamburger {
      width: 150px;
      height: auto;
    }
  }
}

// 01
.container-one{
  height: 670px;
  @include hamburger {
    height: auto;
    padding: 0px 0;
  }
}
.swiperAbout{
  padding-left: 30px !important;
  @include hamburger {
    padding-left: 0 !important;
  }
}

.container-right{
  @include hamburger {
    display: none;
  }
}
.container-more-btn{
  display: none;
  width: 80%;
  height: 40px;
  line-height: 40px;
  /* 2 */
  border-radius: 20px;
  margin: 10px auto !important;
  @include hamburger {
    display: inline-block;
  }
}
// 02开始
.container-two{
  @include hamburger {
    padding: 0 !important;
  }
  .container-two-top{
    height: 560px;
    @include hamburger {
      height: 180px;
    }
    .container-left{
      @include hamburger {
        height: auto;
      }
    }
    .container-center{
      @include hamburger {
        padding: 0 12px !important;
      }
      .process-pc{
        background: url(../assets/images/technological-process.png) no-repeat center center;
        background-size: 100% 100%;
      }
      .process-mb{
        height: 171px;
        background: url(../assets/images/technological-process-m.png) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
  .container-two-bottom{
    padding-bottom: 100px !important;
    background: #CA4938 url(../assets/images/texture.png) repeat center center;
    background-size: 100% 100%;
    box-sizing: border-box !important;
    padding-top: 150px !important;
    @include hamburger {
      padding: 80px 12px 20px !important;
      // height: auto;
    }
    .container-two-bottom-left{
      padding-left: 8% !important;
      @include hamburger {
        padding-left: 0 !important;
      }
      .container-two-bottom-left-tag{
        @include hamburger {
          height: 40px;
        }
      }
      .container-two-bottom-left-title{
        @include hamburger {
          font-size: 18px;
        }
      }
      .mt-30{
        @include hamburger {
          margin-top: 10px;
          line-height: 1.5;
        }
      }
    }
    .container-two-bottom-right{
      @include hamburger {
        display: none;
      }
      margin-right: 100px !important;
    }
  }
}

// 03
.container-three{
  height: 937px;
  position: relative;
  @include hamburger {
    height: auto;
    position: unset;
    margin-top: 20px !important;
    background-color: #fff;
  }
  .bar{
    border-left: 2px solid #fff;
  }
  .container-left{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    @include hamburger {
      position: unset;
    }
  }
  .container-right{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}

// 04开始
.container-four{
  height: 937px;
  @include hamburger {
    height: auto;
  }
  background: #CA4938 url('https://s3.moke.cloud/shuangmei/bf5394c8-432d-460c-afb3-6a28b12dd2a9.jpg') no-repeat center center;
  background-size: cover;
  .bar{
    border-left: 2px solid #fff;
  }
  .container-center{
    padding: 100px 0;
    @include hamburger {
      padding: 12px !important;
    }
    .container-center-box{
      background: rgba(30, 69, 108, 0.8);
      @include hamburger {
        padding: 16px 0;
      }
      .text-40{
        @include hamburger {
          font-size: 18px;
        }
      }
      .container-auto{
        padding: 0 150px;
        height: 510px;
        margin-top: 40px;
        @include hamburger {
          padding: 0 12px;
          margin-top: 15px;
        }
      }
    }
  }
  /*定义滚动条高宽及背景
  高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar
  {
      width: 4px;
      height:10px;
      background-color:#F5F5F5;
  }
  /*定义滚动条轨道
  内阴影+圆角*/
  ::-webkit-scrollbar-track
  {
      -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
      border-radius:10px;
      background-color:#1E456C;
  }
  /*定义滑块
  内阴影+圆角*/
  ::-webkit-scrollbar-thumb
  {
      border-radius:10px;
      -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
      background-color:#F5F5F5;
  }
}

// 05开始
.container-five{
  position: relative;
  @include hamburger {
    padding: 0 !important;
  }
  .container-five-bg{
    width: 100%;
    height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    @include hamburger {
      height: 200px;
    }
  }
  .container-five-top{
    height: 500px;
    @include hamburger {
      height: auto;
    }
    .container-left{
      @include hamburger {
        height: auto;
      }
    }
    .container-center{
      height: 580px;
      margin-top: 60px !important;
      background: url(../assets/images/about01.jpg) no-repeat center;
      background-size: cover;
      @include hamburger {
        height: auto;
        margin-top: 0 !important;
        padding: 0 12px !important;
        background: unset;
      }
      .process-mb{
        height: 140px;
      }
    }
  }
  .container-five-bottom{
    height: 771px;
    background: #CA4938 url(../assets/images/texture.png) repeat center center;
    background-size: 100% 100%;
    box-sizing: border-box !important;
    padding-bottom: 100px !important;
    padding-top: 70px !important;
    @include hamburger {
      padding-top: 80px !important;
      // height: auto;
      padding-bottom: 20px !important;
    }
    .container-five-bottom-left{
      padding-left: 8% !important;
      @include hamburger {
        padding-left: 0 !important;
      }
      .container-five-bottom-left-title{
        @include hamburger {
          font-size: 18px;
        }
      }
    }
    .container-two-bottom-right{
      @include hamburger {
        display: none;
      }
      margin-right: 100px !important;
    }
  }
}

.center-bg{
  background-image: url(../assets/images/center-bg.png);
  width: 100%;
  background-size: 100% auto;
  background-position-y: center;
  background-repeat: no-repeat;
  background-color: #d8d8d8;
  background-attachment: fixed;
  padding-top: 670px;
  @include hamburger {
    // padding-top: 250px;
    // background-size: cover;
    display: none;
  }
  position: relative;
  overflow: hidden;
}

</style>
